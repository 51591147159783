<template>
  <div>
    <div class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <h3 class="is-size-3">Levels</h3>

        </div>

      </div>

      <!-- Right side -->
      <div class="level-right">
        <div class="level-item">
          <b-tooltip append-to-body
                     v-if="canCreateLevel"
                     label="Create a new level in this campus"
                     size="is-small"
                     type="is-success"
                     multilined
          >

            <a
                class="card-header-icon has-text-primary px-0 py-0"
                @click.prevent.stop="startCreateLevel"
            >
              <b-icon :icon="$tc('icons.create')" size="is-medium"/>
            </a>

          </b-tooltip>

        </div>

      </div>
    </div>
    <!--    -->
    <!--    <PhasesFilter @filtered="handleFilter"-->
    <!--                  :type="'filter'"-->
    <!--                  @cleared="$store.state.campus.filter_phase = null"-->
    <!--                  :campus_id="activeCampusId"></PhasesFilter>-->
    <transition name="fade">
      <!--      <StagesFilter v-if="$store.state.campus.filter_phase"-->
      <!--                    @filtered="({term})=>$store.dispatch('campus/setFilterStageTerm',term)"-->
      <!--                    @cleared="$store.dispatch('campus/setFilterStageTerm',null)"-->
      <!--                    :campus_id="activeCampusId"-->
      <!--      ></StagesFilter>-->
    </transition>

    <hr>
    <div ref="phasesContainer" @mouseenter="psUpdate" class="phasesContainer">
      <div v-if="active_campus!==null && active_campus.phases.length>0 && loaded">

        <draggable
            tag="section"

            v-model="sortedLevels"
            group="levels"
            :disabled="!$store.getters['entities/user-permissions/find']('edit levels')"
            :force-fallback="true"
              handle=".level-handle"
        >
          <transition-group name="list"
          >
            <b-collapse
                class="card un-card"
                v-for="level in levels" :key="level.id"
                animation="slide"
                :open="openLevel === level.id"
                @open="handleOpen(level.id)">
              <div
                  @click.prevent.self
                  slot="trigger"
                  slot-scope="props"
                  class="card-header has-background-primary-light"
              >
                <p class="card-header-title ">
                  <b-icon
                      :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                  {{ level.name }}
                </p>
                <a class="card-header-icon is-flex is-align-items-center">


                  <b-tooltip append-to-body
                             v-if="!!$store.getters['entities/user-permissions/find']('edit levels') && canEditLevel"
                             label="Edit"
                             position="is-left"
                             size="is-small"
                             type="is-primary"
                  >
                    <a

                        class="card-header-icon has-text-black is-flex  px-0 py-0"
                        @click.prevent.stop="startEditLevel(level)"
                    >
                      <b-icon :icon="$tc('icons.edit')"
                      ></b-icon>
                    </a>
                  </b-tooltip>

                  <b-tooltip append-to-body
                             v-if="!!$store.getters['entities/user-permissions/find']('edit levels')"
                             label="Move"
                             position="is-left"
                             class="is-flex"

                             size="is-small"
                             type="is-primary"
                  >
                    <span class="is-flex"><b-icon @click.native.stop type="is-primary" class="level-handle" :icon="$tc('icons.move')"
                    ></b-icon></span>
                  </b-tooltip>

                  <b-tooltip append-to-body
                             v-if="!!$store.getters['entities/user-permissions/find']('delete levels')"
                             label="Delete"
                             position="is-left"

                             size="is-small"
                             type="is-primary"
                  >
                    <a
                        class="card-header-icon has-text-danger px-0 py-0"
                        @click.prevent.stop="startDeleteLevel(level.id)"
                    >
                      <b-icon :icon="$tc('icons.delete')"/>
                    </a>

                  </b-tooltip>
                </a>
              </div>

              <PhasesComponent
                  @start-create-phase="startCreatePhase(level.id)"
                  :show-classes="showClasses"
                  @create-stage="startCreateStage"
                  @order-changed="updateOrder"
                  @edit-phase="startEditPhase"
                  :canCreateStage="canCreateStage"
                  :canEditStage="canEditStage"
                  :canDeleteStage="canDeleteStage"
                  :canCreateClass="canCreateClass"
                  :canEditClass="canEditClass"
                  :canDeleteClass="canDeleteClass"
                  :canEditPhase="canEditPhase"
                  :canCreateLevel="canCreateLevel"
                  :canDeletePhase="canDeletePhase"
                  :canSelectPhase="canSelectPhase"
                  :selected-phase="selected_phase"
                  :showExtramurals="showExtramurals"
                  :level="level"/>
            </b-collapse>

          </transition-group>
        </draggable>

      </div>
    </div>
  </div>
</template>
<script>
import PhasesComponent from '@/components/panelled-dash/Phases'
// import StagesFilter from "@/components/panelled-dash/StagesFilter"
import PerfectScrollbar from "perfect-scrollbar";
import PhaseForm from "@/components/panelled-dash/PhaseForm";
import StageForm from "@/components/panelled-dash/StageForm";
import Phase from "@/models/Phase";
import LearningStream from "@/models/LearningStream";
import {mapState} from "vuex";
import Level from "@/models/Level";
import draggable from 'vuedraggable'
import LevelForm from "@/components/panelled-dash/LevelForm";


export default {
  name: 'PhasesPanel',
  components: {PhasesComponent, draggable},
  data() {
    return {
      openLevel: 0,
      ps: null,
      filterTerm: null, loaded: false
    }
  },
  methods: {
    handleOpen(id) {
      this.openLevel = id
    },
    deleteLevel(id) {
      Level.Delete(id).then(() => {
        this.$buefy.snackbar.open(`Level deleted!`)
      })
    },
    startDeleteLevel(level_id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting level',
        confirmText: 'Delete Level',
        hasIcon: true,
        type: 'is-primary',
        message: 'Are you sure you want to delete this level?',
        onConfirm: () => this.deleteLevel(level_id)
      })
    },
    updateLevelOrder(element) {
      Level.update(element)
      Level.Update(element, false)
    }, updateOrder(element) {
      Phase.update(element)
      Phase.Update(element, false)
    },
    handleFilter({term}) {
      this.$store.state.campus.filter_phase = term
    },
    psUpdate() {
      if (this.ps) {
        this.ps.update();
      }
    }, startCreateLevel() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
          campus_id: this.activeCampusId,
        },
        component: LevelForm,
        hasModalCard: true,
        trapFocus: true
      })
    }, startCreatePhase(level_id) {

      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
          campus_id: this.activeCampusId,
          level_id: level_id
        },
        component: PhaseForm,
        hasModalCard: true,
        trapFocus: true
      })
    }, startEditPhase(phase) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          phase: phase
        },
        component: PhaseForm,
        hasModalCard: true,
        trapFocus: true
      })
    }, startEditLevel(level) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          level: level
        },
        component: LevelForm,
        hasModalCard: true,
        trapFocus: true
      })
    }, startCreateStage(data) {
      this.$buefy.modal.open({
        parent: this,
        props: data,
        component: StageForm,
        hasModalCard: true,
        trapFocus: true
      })
    },
  },
  watch: {
    activeCampusId() {


      Phase.FetchAll({page: 1, limit: 999}, {
        campus_id: this.activeCampusId,
        year: this.$store.state.dates.year
      }, ['learning_stream'])
      Level.FetchAll({page: 1, limit: 999}, {campus_id: this.activeCampusId})
    },
    year() {


      Phase.FetchAll({page: 1, limit: 999}, {
        campus_id: this.activeCampusId,
        year: this.$store.state.dates.year
      }, ['learning_stream'])
      Level.FetchAll({page: 1, limit: 999}, {campus_id: this.activeCampusId})
    }
  },
  computed: {
    sortedLevels: {
      get() {
        return this.levels
      },
      set(evt) {
        evt.map((currentelement, index) => {
          if (currentelement.ordinality !== index) {
            currentelement.ordinality = index;
            this.updateLevelOrder(currentelement)
          }
        });
      },

    },
    ...mapState('dates', ['year']),
    levels() {
      return Level.query().where('campus_id', this.activeCampusId).orderBy('ordinality', 'asc').get()
    },

  },
  mounted() {
    this.ps = new PerfectScrollbar(this.$refs.phasesContainer);

    Promise.all([Phase.FetchAll({page: 1, limit: 999}, {
          campus_id: this.activeCampusId,
          year: this.$store.state.dates.year
        }, ['learning_stream']), Level.FetchAll({page: 1, limit: 999}, {campus_id: this.activeCampusId})
          , LearningStream.FetchAll()]
    ).then(() => {
      this.handleOpen(this.levels[0].id)
      this.loaded = true
    })
  },
  props: {
    canEditLevel: {type: Boolean, default: false, required: false},

    canCreateClass: {
      type: Boolean, default() {
        return false
      }
    },
    canEditClass: {
      type: Boolean, default() {
        return false
      }
    },

    canDeleteClass: {
      type: Boolean, default() {
        return false
      }
    },
    showClasses: {
      type: Boolean,
      default() {
        return false
      }
    },
    canCreatePhase: {
      type: Boolean, default() {
        return false
      }
    },
    canCreateStage: {
      type: Boolean, default() {
        return false
      }
    },
    canEditStage: {
      type: Boolean, default() {
        return false
      }
    },
    canDeletePhase: {
      type: Boolean, default() {
        return false
      }
    },
    canDeleteStage: {
      type: Boolean, default() {
        return false
      }
    },
    canEditPhase: {
      type: Boolean, default() {
        return false
      }
    }, canSelectPhase: {
      type: Boolean, default() {
        return false
      }
    }, canCreateLevel: {
      type: Boolean, default() {
        return false
      }
    }, showExtramurals: {
      type: Boolean, default() {
        return true
      }
    },
    activeCampusId: {},
    active_campus: {},
    selected_phase: {}
  }
}
</script>
